<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" ref="chequelistForm" @submit.prevent="getCheques">
                    <v-card elevation="1" color="blue-grey lighten-3">
                        <v-card-text class="py-0">
                            <v-row dense>
                                <v-col cols="1">Form Date</v-col>
                                <v-col cols="2">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model="filter.fromDate"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.fromDate" :rules="[v => !!v]"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="1">To Date</v-col>
                                <v-col cols="2">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                hide-details
                                                v-model="filter.toDate"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.toDate" :rules="[v => !!v]"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col md="3">
                                    <v-btn type="submit" color="info" small style="margin-top:-1px" class="mr-1">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="chequeInfoHeaders"
                    :loading="$store.getters['cheque/loading']"
                    :items="$store.getters['cheque/cheques']"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Cheque Information List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Cheque"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="cheque = item;chequeDetails = true" color="primary" v-on="on">mdi-eye</v-icon>
							</template>
							<span>View</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <v-dialog v-model="chequeDetails" max-width="500" light>
					<v-card>
						<v-toolbar dark dense :elevation="0" color="#546E7A">
							<v-toolbar-title>Cheque Details</v-toolbar-title>
						</v-toolbar>
						<v-card-text class="py-1">
							<table>
								<tr>
									<td>Cheque No</td>
									<td> : </td>
									<td>{{ cheque.cheque_number }}</td>
								</tr>
								<tr>
									<td>Bank Name</td>
									<td> : </td>
									<td>{{ cheque.bank_name }}</td>
								</tr>
								<tr>
									<td>Branch Name</td>
									<td> : </td>
									<td>{{ cheque.branch_name }}</td>
								</tr>
								<tr>
									<td>Patient Name</td>
									<td> : </td>
									<td>{{ cheque.patient.name }}</td>
								</tr>
								<tr>
									<td>Mobile</td>
									<td> : </td>
									<td>{{ cheque.patient.mobile }}</td>
								</tr>
								<tr>
									<td>Date</td>
									<td> : </td>
									<td> {{ cheque.date }} </td>
								</tr>
								<tr>
									<td>Cheque Date</td>
									<td> : </td>
									<td>{{ cheque.cheque_date }}</td>
								</tr>
								<tr>
									<td>Reminder Date</td>
									<td> : </td>
									<td>{{ cheque.reminder_date }}</td>
								</tr>
								<tr>
									<td>Submit Date</td>
									<td> : </td>
									<td>{{ cheque.reminder_date }}</td>
								</tr>
								<tr>
									<td>Amount</td>
									<td> : </td>
									<td>{{ cheque.cheque_amount }}</td>
								</tr>
								<tr>
									<td>Note</td>
									<td> : </td>
									<td>{{ cheque.note }}</td>
								</tr>
								<tr>
									<td>Cheque Status</td>
									<td> : </td>
									<td>{{ cheque.cheque_status }}</td>
								</tr>
							</table>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn small color="error" dark @click="chequeDetails = false">Close</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'ChequeList',
    data: ()=> ({
        chequeInfoHeaders: [
            { text: 'Cheque Date', value: 'cheque_date' },
            { text: 'Submit Date', value: 'submit_date' },
            { text: 'Cheque No', value: 'cheque_number' },
            { text: 'Bank Name', value: 'bank_name' },
            { text: 'Patient Name', value: 'patient.name' },
            { text: 'Cheque Status', value: 'cheque_status' },
            { text: 'Cheque Amount', value: 'cheque_amount' },
            { text: 'Action', value: 'action' },
        ],

        filter: {
            fromDate: new Date().toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
        },

        chequeDetails: false,
        cheque: {
            patient: {}
        }

    }),

    methods: {
        getCheques() {
            this.$store.dispatch("cheque/getCheques", { apiArg: this.filter });
        },
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
    table {
	border:none;
	td {
		padding:0px 10px;
		&:first-child {
			font-weight: bold;
		}
    }
}
</style>